<template>
  <div class="container">
    <NavBar />
    <div class="box">
      <div class="cell border" @click="toBind">
        <div class="label">绑定手机</div>
        <div class="value">
          <span>{{ userInfo.tel }}</span>
          <van-icon size="18px" color="#aaaaaa" name="arrow" />
        </div>
      </div>
      <div class="cell" @click="toRealName">
        <div class="label">实名认证</div>
        <div class="value">
          <span
            v-if="userInfo.idCard && userInfo.realName"
            style="color: #cb7e0e"
            >已认证</span
          >
          <span v-else>未认证</span>
          <van-icon size="18px" color="#aaaaaa" name="arrow" />
        </div>
      </div>
    </div>
    <!-- <van-button class="btn" round color="#CB7E0E" block>退出登录</van-button> -->
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
export default {
  components: {
    NavBar
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  methods: {
    toRealName() {
      if (this.userInfo.idCard && this.userInfo.realName) return false
      this.$router.push({ name: 'RealName' })
    },
    toBind() {
      this.$router.push({ name: 'BindPhone' })
    }
  }
}
</script>

<style lang="less" scoped>
.btn {
  width: 313px;
  height: 42px;
  margin: 50px auto;
}
.box {
  margin-top: 10px;
  .cell {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 44px;
    padding: 12px 16px;
    background-color: @bg-dark;
    .label {
      font-size: 14px;
      color: @text-color-1;
    }
    .value {
      display: flex;
      align-items: center;
      font-size: 14px;
      span {
        display: inline-block;
        color: @text-color-2;
        margin-right: 4px;
      }
    }
  }
  .border {
    border-bottom: 1px solid #000000;
  }
}
</style>
